@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@100;200;300;400;500;600;700;800;900&display=swap");



@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
    .btn{
        @apply text-lg lg:text-xl lg:w-64 font-medium mx-auto flex items-center justify-center text-center border border-transparent h-[46px] px-8 rounded-full shadow-sm transition-all duration-300 hover:-translate-y-1;
    }
    .btn-dark{
        @apply bg-primary text-white border-primary hover:bg-primary/80;
        /* @apply bg-primary text-white border-primary hover:bg-primary/80; */
    }
    .btn-white-lg{
        @apply bg-white border-white !font-semibold !text-xl lg:!text-[22px] text-black w-full lg:w-96 hover:bg-black hover:border-black hover:text-white;
    }
    
    .btn-white{
        @apply bg-white border-white text-rose-800 hover:bg-rose-300/80 hover:text-white;
    }
    .btn-black{
        @apply bg-black text-white border-black hover:bg-black/80 focus:bg-black;
    
    }
}

html{
    scroll-behavior: smooth
}

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
   /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
  }
}